
import SidebarWrapper from "@/common/components/sidebar-wrapper/SidebarWrapper.vue";
import MainContent from "./components/MainContent.vue";
import VersionControls from "./components/VersionControls.vue";
import Pagetree from "@/common/components/pagetree/Pagetree.vue";
import { Vue, Options } from "vue-class-component";
import { Provide } from "vue-property-decorator";
import { deltaService } from "./services/delta-service";
import {
	IServiceFileTreeModel,
	IServiceFileTreeItem
} from "./models/service-file-tree";
import { IVersionChangeEvent, IVersions } from "./models/versions";
import { IServiceFileDelta } from "./models/service-file-delta";
import { ITagFilter } from "@/common/components/pagetree/models/tag-filter";

@Options({
	components: {
		SidebarWrapper,
		MainContent,
		VersionControls,
		Pagetree
	}
})
export default class DeltaView extends Vue {
	@Provide() public versionsRef = { value: {} as IVersions };

	@Provide() public fileDeltaRef = { value: {} as IServiceFileDelta };

	public pagetree: IServiceFileTreeModel | null = null;
	public isPagetreeLoading: boolean = false;

	public lang!: string;
	public tagFilter: ITagFilter | null = null;
	public selectedItem: IServiceFileTreeItem | null = null;

	async created(): Promise<void> {
		this.lang = this.$route.params.lang as string;

		this.versionsRef.value = await deltaService.getVersions();
	}

	public onTagFilterChange(tagFilter: ITagFilter): void {
		this.tagFilter = tagFilter;
	}

	public async onVersionChange(e: IVersionChangeEvent): Promise<void> {
		if (e.versionFrom && e.versionTo && e.releaseFrom && e.releaseTo) {
			this.isPagetreeLoading = true;
			this.versionsRef.value.disableInputs = true;
			this.pagetree = await deltaService.getServiceFileTree(
				e.releaseFrom.metadataId,
				e.releaseTo.metadataId,
				e.ignoreNS
			);
			delete this.versionsRef.value.disableInputs;
			this.isPagetreeLoading = false;
		}
	}

	public async onPagetreeItemSelected(
		item: IServiceFileTreeItem | any
	): Promise<void> {
		if (item?.srcFileId || item?.targetFileId) {
			this.fileDeltaRef.value = { loading: true } as IServiceFileDelta;

			if (item.targetFileId === null || (item.targetFileId ?? 0) < 1) {
				this.fileDeltaRef.value = await deltaService.getServiceFile(
					item.srcFileId ?? 0
				);
			} else if (
				item.srcFileId === null ||
				(item.srcFileId ?? 0) < 1 ||
				item.srcFileId === item.targetFileId
			) {
				this.fileDeltaRef.value = await deltaService.getServiceFile(
					item.targetFileId ?? 0
				);
			} else {
				this.fileDeltaRef.value = await deltaService.getServiceFileDelta(
					item.srcFileId ?? 0,
					item.targetFileId ?? 0
				);
			}
		} else {
			this.fileDeltaRef.value = {} as IServiceFileDelta;
		}
	}
}
