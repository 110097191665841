<template>
	<div class="version-control">
		<h5 class="version-control-header">
			{{ $t("delta.versionControls.header") }}
		</h5>
		<div class="version-input-group-container">
			<span class="container-caption">{{
				$t("delta.versionControls.caption")
			}}</span>
			<div class="version-input-group">
				<label> {{ $t("delta.versionControls.from") }}: </label>
				<select
					class="version-select"
					v-model="versionFrom"
					@change="onVersionFromChange"
					v-bind:disabled="inputsDisabled"
				>
					<option v-for="version of versionList">
						{{ version }}
					</option>
				</select>
				<select
					class="relese-select"
					v-model="releaseFrom"
					@change="onReleaseChange"
					v-bind:disabled="inputsDisabled"
				>
					<option v-for="release of releaseList_From" :value="release">
						{{ release.number ?? "" }}
					</option>
				</select>
			</div>
			<div class="version-input-group">
				<label> {{ $t("delta.versionControls.to") }}: </label>
				<select
					class="version-select"
					v-model="versionTo"
					@change="onVersionToChange"
					v-bind:disabled="inputsDisabled"
				>
					<option v-for="version of versionList">
						{{ version }}
					</option>
				</select>
				<select
					class="relese-select"
					v-model="releaseTo"
					@change="onReleaseChange"
					v-bind:disabled="inputsDisabled"
				>
					<option v-for="release of releaseList_To" :value="release">
						{{ release.number ?? "" }}
					</option>
				</select>
			</div>
			<div class="options-input-group">
				<input
					class="option-input styled-checkbox"
					type="checkbox"
					id="option-checkbox-ignorens"
					value="ignoreNS"
					v-model="checkedOptions"
					@change="onOptionsChange"
					v-bind:disabled="inputsDisabled"
				/>
				<label class="option-input-label" for="option-checkbox-ignorens">{{
					$t("delta.versionControls.options.ignoreNS")
				}}</label>
			</div>
		</div>
		<div class="filter-container">
			<span class="container-caption">{{
				$t("delta.versionControls.tagFilter.caption")
			}}</span>
			<div class="d-flex flex-wrap filter-input-group-container">
				<div class="filter-input-group" v-for="tag in filterTags">
					<input
						class="filter-input styled-checkbox"
						type="checkbox"
						:id="'tag-filter-checkbox-' + tag"
						:value="tag"
						v-model="checkedFilterTags"
						@change="onFilterChange"
						v-bind:disabled="inputsDisabled"
					/>
					<label
						class="filter-input-label"
						:for="'tag-filter-checkbox-' + tag"
						>{{ $t("delta.versionControls.tagFilter.tags." + tag) }}</label
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { unref } from "vue";
export default {
	inject: ["versionsRef"],
	data: function () {
		return {
			versionFrom: "",
			versionTo: "",
			releaseFrom: {},
			releaseTo: {},
			filterTags: [
				"service-unmodified",
				"service-modified",
				"service-new",
				"service-removed"
			],
			checkedFilterTags: [],
			persistentFilterTags: ["group", "schema"],
			checkedOptions: ["ignoreNS"]
		};
	},
	computed: {
		versions: function () {
			return unref(this.versionsRef.value)?.versions || [];
		},
		versionList: function () {
			return Object.keys(this.versions) ?? {};
		},
		releaseList_From: function () {
			return this.versions[this.versionFrom]?.releases || [];
		},
		releaseList_To: function () {
			return this.versions[this.versionTo]?.releases || [];
		},
		inputsDisabled: function () {
			return this.versions.disableInputs ? true : false;
		}
	},
	methods: {
		onVersionFromChange: function () {
			if (this.releaseList_From.length === 1) {
				this.releaseFrom = this.releaseList_From[0];
				this.emitVersion();
			} else {
				this.releaseFrom = {};
			}
		},
		onVersionToChange: function () {
			if (this.releaseList_To.length === 1) {
				this.releaseTo = this.releaseList_To[0];
				this.emitVersion();
			} else {
				this.releaseTo = {};
			}
		},
		onReleaseChange: function () {
			this.emitVersion();
		},
		onOptionsChange: function () {
			this.emitVersion();
		},
		emitVersion: function () {
			if (this.releaseFrom.number != null && this.releaseTo.number != null) {
				this.$emit("version-changed", {
					versionFrom: this.versionFrom,
					versionTo: this.versionTo,
					releaseFrom: this.releaseFrom,
					releaseTo: this.releaseTo,
					ignoreNS: this.checkedOptions.includes("ignoreNS")
				});
				this.checkedFilterTags = [];
				this.checkedFilterTags.push(...this.filterTags);
			}
		},
		onFilterChange: function () {
			this.$emit("tag-filter-changed", {
				tags: this.checkedFilterTags.concat(this.persistentFilterTags),
				allTagsSelected: this.filterTags === this.checkedFilterTags
			});
		}
	},
	created: function () {
		this.checkedFilterTags.push(...this.filterTags);
	}
};
</script>
<style scoped>
.version-control-header {
	color: #84c9da;
}

.version-input-group-container,
.filter-container {
	vertical-align: top;
	display: inline-block;
}
.version-input-group-container .container-caption,
filter-container .container-caption {
	display: block;
	margin-bottom: 0.3em;
}
.version-input-group-container {
	width: 40%;
}
.filter-container {
	width: 60%;
}

.filter-input-group {
	width: 50%;
	height: 1.5em;
}
.filter-input-label,
.option-input-label {
	margin-bottom: 0.1em;
	user-select: none;
}
.filter-input-group-container {
	padding-top: 0.3em;
}

.version-input-group {
}
.version-input-group label {
	width: 45px;
}
.version-input-group .version-select {
	width: 60px;
}
.version-input-group .relese-select {
	margin-left: 10px;
	width: 45px;
}
.styled-checkbox {
	position: absolute;
	opacity: 0;
}

.styled-checkbox + label {
	position: relative;
	cursor: pointer;
	padding: 0;
	white-space: nowrap;
}

.styled-checkbox + label:before {
	content: "";
	margin-right: 4px;
	display: inline-block;
	vertical-align: text-top;
	width: 13px;
	height: 13px;
	background: white;
	border: 1px gray solid;
	border-radius: 3px;
	vertical-align: baseline;
}

.styled-checkbox:hover + label:before {
	background: lightgray;
}

/*.styled-checkbox:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }*/

.styled-checkbox:checked + label:before {
	background: #007d9b;
	border: none;
}

.styled-checkbox:disabled + label {
	color: #b8b8b8;
	cursor: auto;
}

.styled-checkbox:disabled + label:before {
	box-shadow: none;
	background: #ddd;
}

.styled-checkbox:checked + label:after {
	content: "";
	position: absolute;
	left: 4px;
	bottom: 8px;
	width: 5px;
	height: 8px;
	border: solid white;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
</style>