import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7164daa8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar-slot" }
const _hoisted_2 = { class: "version-control-container" }
const _hoisted_3 = { class: "pagetree-header" }
const _hoisted_4 = { class: "pagetree-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_version_controls = _resolveComponent("version-controls")!
  const _component_pagetree = _resolveComponent("pagetree")!
  const _component_main_content = _resolveComponent("main-content")!
  const _component_sidebar_wrapper = _resolveComponent("sidebar-wrapper")!

  return (_openBlock(), _createBlock(_component_sidebar_wrapper, null, {
    sidebar: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_version_controls, {
            onVersionChanged: _ctx.onVersionChange,
            onTagFilterChanged: _ctx.onTagFilterChange
          }, null, 8, ["onVersionChanged", "onTagFilterChanged"])
        ]),
        _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.$t("delta.pagetree.header")), 1),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_pagetree, {
            selectedItem: _ctx.selectedItem,
            "onUpdate:selectedItem": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItem) = $event)),
              _ctx.onPagetreeItemSelected
            ],
            isLoading: _ctx.isPagetreeLoading,
            pagetree: _ctx.pagetree,
            tagFilter: _ctx.tagFilter,
            emptyText: _ctx.$t('delta.pagetree.empty')
          }, null, 8, ["selectedItem", "isLoading", "pagetree", "tagFilter", "emptyText", "onUpdate:selectedItem"])
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_main_content)
    ]),
    _: 1
  }))
}