import { fetchService } from "@/core/services/fetch-service";
import { IServiceFileDelta } from "../models/service-file-delta";
import { IServiceFileTreeModel } from "../models/service-file-tree";
import { IVersions } from "../models/versions";

export default class DeltaService {
	public getServiceFileTree(metadataIdFrom: number, metadataIdTo: number, ignoreNameSpace: boolean): Promise<IServiceFileTreeModel> {
		const queryParams = new URLSearchParams({
			MetadataId_From: metadataIdFrom.toString(),
			MetadataId_To: metadataIdTo.toString(),
			IgnoreNS: ignoreNameSpace.toString()
		});
		return fetchService.get<IServiceFileTreeModel>("/Delta/api/ServiceFileTree", queryParams);
	}

	public getVersions(): Promise<IVersions> {
		return fetchService.get<IVersions>("/Delta/api/Versions");
	}

	public getServiceFile(fileId: number): Promise<IServiceFileDelta> {
		return fetchService.get<IServiceFileDelta>("/Delta/api/ServiceFile/" + fileId)
	}

	public getServiceFileDelta(srcFileId: number, targetFileId: number): Promise<IServiceFileDelta> {
		const queryParams = new URLSearchParams({
			SrcFileId: srcFileId.toString(),
			TargetFileId: targetFileId.toString()
		});
		return fetchService.get<IServiceFileDelta>("/Delta/api/ServiceFile/Delta", queryParams);
	}
}

export const deltaService = new DeltaService();