<template>
	<div class="service-file-delta">
		<h5 class="service-file-delta-header">
			{{ $t("delta.serviceFileDelta.header") }}
		</h5>
		<div class="seek-changes-container" v-if="!empty && !loading">
			<span class="seek-change-btn" @click="seekPreviousChange()"
				>&lt;&lt; {{ $t("delta.serviceFileDelta.previous") }}</span
			>
			<span class="seek-change-btn" @click="seekNextChange()"
				>{{ $t("delta.serviceFileDelta.next") }} &gt;&gt;</span
			>
		</div>
		<template v-if="empty">
			<div>{{ $t("delta.serviceFileDelta.empty") }}</div>
		</template>
		<template v-else-if="loading">
			<loading-circle></loading-circle>
		</template>
		<template v-else>
			<div class="file-lines-scrollable">
				<span
					v-for="(line, index) in fileDelta.fileLines"
					class="file-line"
					:id="'service-file-delta__file-line-' + index"
					:data-state="line.state"
					>{{ line.value }}</span
				>
			</div>
		</template>
	</div>
</template>

<script>
import LoadingCircle from "@/common/components/loading-circle/LoadingCircle.vue";
import _ from "lodash";

export default {
	inject: ["fileDeltaRef"],
	data: function () {
		return {
			componentName: "service-file-delta",
		};
	},
	components: {
		LoadingCircle,
	},
	methods: {
		seekPreviousChange: function () {
			let i = this.fileDelta.currentChangeIndex ?? 0;
			while (i - 1 >= 0) {
				i--;
				if (this.fileDelta.fileLines[i].state === "unchanged") {
					break;
				}
			}
			while (i - 1 >= 0) {
				i--;
				if (this.fileDelta.fileLines[i].state !== "unchanged") {
					break;
				}
			}
			this.scrollToLine(i);
		},
		seekNextChange: function () {
			let i = this.fileDelta.currentChangeIndex ?? 0;
			while (i + 1 < this.fileDelta.fileLines.length) {
				i++;
				if (this.fileDelta.fileLines[i].state === "unchanged") {
					break;
				}
			}
			while (i + 1 < this.fileDelta.fileLines.length) {
				i++;
				if (this.fileDelta.fileLines[i].state !== "unchanged") {
					break;
				}
			}
			this.scrollToLine(i);
		},
		initScrollPosition: function () {
			this.fileDelta.currentChangeIndex = 0;
			let i = 0;
			let found = false;
			while (i + 1 < this.fileDelta.fileLines.length) {
				i++;
				if (this.fileDelta.fileLines[i].state !== "unchanged") {
					found = true;
					break;
				}
			}
			if (!found) {
				i = 0;
			}
			this.scrollToLine(i);
		},
		scrollToLine: function (index) {
			document
				.getElementById("service-file-delta__file-line-" + index)
				.scrollIntoView({ behavior: "smooth", block: "center" });
			this.fileDelta.currentChangeIndex = index;
		},
	},
	watch: {
		fileDelta: function () {
			if (!this.empty && !this.loading) {
				this.$nextTick(
					function () {
						this.initScrollPosition();
					}.bind(this)
				);
			}
		},
	},
	computed: {
		fileDelta: function () {
			return this.fileDeltaRef.value;
		},
		empty: function () {
			return _.isEmpty(this.fileDelta);
		},
		loading: function () {
			return !!this.fileDelta.loading;
		},
	},
};
</script>
<style scoped>
.service-file-delta {
	height: calc(100vh - 5.7em);
	overflow: hidden;
}
.service-file-delta-header {
	color: #84c9da;
	display: inline-block;
}
.seek-changes-container {
	display: inline-block;
	margin-left: 30px;
}
.seek-changes-container .seek-change-btn {
	cursor: pointer;
	color: #2b6c7b;
	text-decoration: underline;
	margin-left: 10px;
	margin-right: 10px;
	user-select: none;
}
.file-lines-scrollable {
	overflow: auto;
	height: calc(100% - 47px);
	border: lightgrey 1px solid;
}
.file-line {
	display: block;
	line-height: 1.2em;
	margin: 2px 0 2px 0;
	white-space: pre;
	padding-left: 10px;
	font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
}
.file-line::before {
	content: " ";
	padding-right: 5px;
}
.file-line[data-state="deleted"] {
	background-color: #fdaeb7;
}
.file-line[data-state="deleted"]::before {
	content: "-";
	padding-right: 5px;
}
.file-line[data-state="inserted"] {
	background-color: #bef5cb;
}
.file-line[data-state="inserted"]::before {
	content: "+";
	padding-right: 5px;
}
</style>
