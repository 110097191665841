<template>
	<service-file-delta></service-file-delta>
</template>

<script>
import ServiceFileDelta from "./ServiceFileDelta.vue";
export default {
	components: {
		ServiceFileDelta,
	},
	data: function () {
		return {
			componentName: " main content ",
		};
	},
};
</script>
